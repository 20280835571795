import type { ReactNode, ComponentProps } from "react";
import classnames from "classnames";

import Icon from "common/core/icon";
import TooltipOverlay from "common/core/tooltip/overlay";
import Link from "common/core/link";
import { QueueCount } from "common/notary/queue";

import Styles from "./index.module.scss";

type NavItemProps = {
  handleUrl: string;
  onClick?: () => void;
  iconName?: string;
  label: ReactNode;
  isCollapsed?: boolean;
  isActive?: boolean;
  queueCountKey?: false | ComponentProps<typeof QueueCount>["countKey"];
  featureAnnouncement?: ReactNode;
};

export function NavItem(props: NavItemProps) {
  const { isCollapsed, label, iconName, queueCountKey, featureAnnouncement } = props;
  return (
    <li className={classnames(Styles.navItemContainer, isCollapsed && Styles.collapsed)}>
      <Link
        className={classnames(Styles.navItem, props.isActive && Styles.activeLink)}
        to={props.handleUrl}
        onClick={props.onClick}
        white
        underlined={false}
        aria-current={props.isActive ? "page" : undefined}
      >
        {iconName && <Icon name={iconName} />}
        <span className={Styles.label}>{label}</span>
        {queueCountKey && (
          <div className={classnames(Styles.queueContainer, isCollapsed && Styles.smallQueue)}>
            <QueueCount countKey={queueCountKey} isSideNav small={isCollapsed} />
          </div>
        )}
      </Link>
      {featureAnnouncement}
      {isCollapsed && (
        <TooltipOverlay aria-hidden="true" trigger="hover" placement="right" size="mini">
          {label}
        </TooltipOverlay>
      )}
    </li>
  );
}
