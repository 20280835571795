import { Navigate } from "react-router-dom";

import APPS from "constants/applications";
import { useIsAuthenticated } from "common/authentication";
import AccountLogin from "common/account/login";
import LoadingIndicator from "common/core/loading_indicator";
import { useQuery } from "util/graphql";
import { setCookie } from "util/cookie";

import ActivationReferral from "./index_query.graphql";

export const ACTIVATION_REFERRING_ORG_COOKIE = "proof-activating-org";

export function ReferralActivation() {
  const { data } = useQuery(ActivationReferral, {});
  const isAuthenticated = useIsAuthenticated();

  if (!data) {
    return <LoadingIndicator />;
  }

  const { referralInfo } = data.viewer;

  // ReferralHandler validates the referral and won't land us here if there isn't a valid referral
  if (referralInfo?.publicOrganization.name) {
    setCookie(ACTIVATION_REFERRING_ORG_COOKIE, referralInfo.publicOrganization.name, {
      expires: 1,
    });
  }

  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  return <AccountLogin signUpPortal={APPS.TITLE_AGENCY} entry={APPS.TITLE_AGENCY} />;
}
