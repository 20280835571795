import { useState, type ComponentProps } from "react";
import { defineMessages, useIntl } from "react-intl";

import { Feature } from "graphql_globals";
import { EVENT } from "constants/analytics";
import { segmentTrack } from "util/segment";
import {
  SETTINGS_PATH,
  EASYLINK_DASHBOARD_PATH,
  TEMPLATES_DASHBOARD_PATH,
  TOOLS_PATH,
  TRANSACTION_PATH,
  IDENTITY_CRM_PATH,
} from "util/routes";
import { ON_DEMAND_QUEUE_PATH } from "common/notary/queue/path";
import {
  isNotaryODN,
  isNotaryNST,
  isNotaryNSTCallReady,
  isNotaryODNCallReady,
  isNotaryIHNCallReady,
} from "common/notary/capacity";
import { useUpcomingPath } from "common/closing_agenda";
import type { ProofPortal } from "constants/app_subdomains";
import { SCHEDULED_PATH, CLOSING_AGENDA_PATH } from "common/closing_agenda/path";
import { usePermissions } from "common/core/current_user_role";
import type { ProofFrame_viewer_user as User } from "common/proof_frame/index.query.graphql";
import {
  TEAM_PATH,
  GET_STARTED_PATH,
  COMPANY_DIRECTORY_PATH,
  USER_MANAGEMENT_PATH,
  DOMAINS_PATH,
  useIsCommandCenter,
  ACCESS_PATH,
  BRAND_PROFILES_PATH,
} from "common/proof_frame/path";
import { NavItem } from "common/proof_frame/org_side_nav/nav_item";
import { useHideGetStarted } from "common/get_started/common/util";
import { useMoveTools, useSettingProfiles } from "util/feature_detection";
import { useShowTools } from "common/tools/util";
import { useShowIdentityCRM } from "common/identity/crm";
import { deleteCookie, getCookie } from "util/cookie";
// eslint-disable-next-line import/no-restricted-paths
import { ACTIVATION_REFERRING_ORG_COOKIE } from "title_portal/account/referral_activation";
import { FeatureAnnouncement } from "common/core/announcements";
import { PENDO_EVENTS } from "util/pendo/events";
import { pendoTrack } from "util/pendo";

import {
  myClosingsLabel,
  sendAndManageLabel,
  onDemandLabel,
  meetingsLabel,
  toolsLabel,
  orgSettingsLabel,
  usersLabel,
  getStartedLabel,
  companyDirectoryLabel,
  userManagementLabel,
  accessLabel,
  identityCRMLabel,
  brandsLabel,
  useIsPathActive,
} from "../../common";

const MESSAGES = defineMessages({
  referringOrgHeading: {
    id: "096007e8-0e5d-4e8e-8bf4-0db4115ecce6",
    defaultMessage: "Use your sponsored EasyLink",
  },
  referringOrg: {
    id: "0b7529cc-c5e9-45f7-98b9-c3e5e556d8db",
    defaultMessage:
      "Navigate to EasyLinks to view and share your sponsored link from {referringOrgName}.",
  },
});

type SideNavLinksProps = {
  portal: ProofPortal;
  sideNavCollapsed: boolean;
  user: User;
};
type NavItemProps = ComponentProps<typeof NavItem>;

export function SideNavLinks({ portal, sideNavCollapsed, user }: SideNavLinksProps) {
  const intl = useIntl();
  const { organization, notaryProfile } = user;
  const isBusinessPortal = portal === "business";
  const isTitlePortal = portal === "title";
  const isLenderPortal = portal === "lender";
  const isIHNOrganization = organization?.featureList.some(
    (feat) => feat === Feature.ORGANIZATION_NOTARIES,
  );
  const [referringOrgName, setReferringOrgName] = useState<string | undefined | null>(
    getCookie(ACTIVATION_REFERRING_ORG_COOKIE),
  );

  function trackAndRemoveActivationCookie() {
    if (!referringOrgName) {
      return;
    }
    pendoTrack(PENDO_EVENTS.ORGANIZATION_ACTIVATION_REFERRAL_ACKNOWLEDGE, { referringOrgName });
    deleteCookie(ACTIVATION_REFERRING_ORG_COOKIE);
    setReferringOrgName(null);
  }
  const { hasPermissionFor } = usePermissions();
  const canViewTeamDetails = hasPermissionFor("viewTeamDetails");
  const canViewTools = useShowTools(
    organization?.featureList,
    organization?.organizationType,
  ).hasToolsFeatures;
  const canViewOrganizationDetails = hasPermissionFor("viewOrganizationDetails");
  const canViewOrganizationTransactions = hasPermissionFor("viewOrganizationTransactions");
  const canViewMeetings = hasPermissionFor("viewMeetings");
  const canUseAccessControls = hasPermissionFor("commandCenterAccessControls");
  const canUseSettingProfiles = hasPermissionFor("commandCenterSettingProfiles");
  const settingProfilesFlag = useSettingProfiles();

  const isEasylinkActive = useIsPathActive(EASYLINK_DASHBOARD_PATH);
  const isTemplatesActive = useIsPathActive(TEMPLATES_DASHBOARD_PATH);
  const hideGetStarted = useHideGetStarted();
  const isCommandCenter = useIsCommandCenter();
  // works for both move-tools and move-tools-real. Can only remove this if removing both.
  const moveTools = useMoveTools();
  const showIdentityCRM = useShowIdentityCRM(organization);

  const sendAndManage: NavItemProps = {
    handleUrl: TRANSACTION_PATH,
    iconName: "dashboard-filled",
    label: intl.formatMessage(sendAndManageLabel),
    isActive: useIsPathActive("/transaction") || isEasylinkActive || isTemplatesActive,
  };
  const myClosings: NavItemProps = {
    handleUrl: TRANSACTION_PATH,
    iconName: "dashboard-filled",
    label: intl.formatMessage(myClosingsLabel),
    isActive: useIsPathActive(TRANSACTION_PATH),
  };
  const onDemand: NavItemProps = {
    handleUrl: ON_DEMAND_QUEUE_PATH,
    iconName: "phone",
    label: intl.formatMessage(onDemandLabel),
    isActive: useIsPathActive(ON_DEMAND_QUEUE_PATH),
    queueCountKey: isNotaryODNCallReady(notaryProfile) && "waitingNODCalls",
  };
  const meetings: NavItemProps = {
    handleUrl: useUpcomingPath(isBusinessPortal ? SCHEDULED_PATH : CLOSING_AGENDA_PATH),
    iconName: "calendar",
    label: intl.formatMessage(meetingsLabel),
    isActive: useIsPathActive("/meetings"),
    queueCountKey:
      (isNotaryNSTCallReady(notaryProfile) ||
        isNotaryIHNCallReady(notaryProfile) ||
        user.verifyAgent) &&
      "waitingClientCalls",
  };
  const identityCRM: NavItemProps = {
    handleUrl: IDENTITY_CRM_PATH,
    iconName: "profile",
    label: intl.formatMessage(identityCRMLabel),
    isActive: useIsPathActive(IDENTITY_CRM_PATH),
  };
  const tools: NavItemProps = {
    handleUrl: TOOLS_PATH,
    iconName: "tools",
    label: intl.formatMessage(toolsLabel),
    isActive: useIsPathActive(TOOLS_PATH),
    onClick: trackAndRemoveActivationCookie,
  };
  const organizationSettings: NavItemProps = {
    handleUrl: SETTINGS_PATH,
    iconName: "settings-filled",
    label: intl.formatMessage(orgSettingsLabel),
    isActive: useIsPathActive(SETTINGS_PATH),
  };
  const teamMembers: NavItemProps = {
    handleUrl: TEAM_PATH,
    iconName: "employees-filled",
    label: intl.formatMessage(usersLabel),
    isActive: useIsPathActive(TEAM_PATH),
  };
  const getStarted: NavItemProps = {
    handleUrl: GET_STARTED_PATH,
    iconName: "get-started-filled",
    label: intl.formatMessage(getStartedLabel),
    onClick: () => segmentTrack(EVENT.CLICKED_GET_STARTED),
    isActive: useIsPathActive(GET_STARTED_PATH),
  };
  const companyDirectory: NavItemProps = {
    handleUrl: COMPANY_DIRECTORY_PATH,
    iconName: "directory",
    label: intl.formatMessage(companyDirectoryLabel),
    isActive: useIsPathActive(COMPANY_DIRECTORY_PATH),
  };
  const userManagement: NavItemProps = {
    handleUrl: USER_MANAGEMENT_PATH,
    iconName: "business",
    label: intl.formatMessage(userManagementLabel),
    isActive: useIsPathActive(USER_MANAGEMENT_PATH),
  };
  const access: NavItemProps = {
    handleUrl: DOMAINS_PATH,
    iconName: "lock",
    label: intl.formatMessage(accessLabel),
    isActive: useIsPathActive(ACCESS_PATH),
  };
  const brands: NavItemProps = {
    handleUrl: BRAND_PROFILES_PATH,
    iconName: "brand",
    label: intl.formatMessage(brandsLabel),
    isActive: useIsPathActive(BRAND_PROFILES_PATH),
  };

  function getNavItems(): NavItemProps[] {
    if (isBusinessPortal && isNotaryODN(notaryProfile)) {
      return [
        getStarted,
        sendAndManage,
        ...(showIdentityCRM ? [identityCRM] : []),
        tools,
        onDemand,
        meetings,
        organizationSettings,
      ];
    } else if (isBusinessPortal && isNotaryNST(notaryProfile)) {
      return [
        getStarted,
        sendAndManage,
        ...(showIdentityCRM ? [identityCRM] : []),
        tools,
        meetings,
        organizationSettings,
      ];
    } else if (isBusinessPortal && isIHNOrganization) {
      return [
        sendAndManage,
        ...(showIdentityCRM ? [identityCRM] : []),
        tools,
        meetings,
        organizationSettings,
        teamMembers,
        getStarted,
      ];
    } else if (isBusinessPortal && user.verifyAgent) {
      return [
        sendAndManage,
        ...(showIdentityCRM ? [identityCRM] : []),
        tools,
        meetings,
        organizationSettings,
        teamMembers,
        getStarted,
      ];
    } else if (isBusinessPortal) {
      return [
        sendAndManage,
        ...(showIdentityCRM ? [identityCRM] : []),
        tools,
        organizationSettings,
        teamMembers,
        getStarted,
      ];
    }

    if (isTitlePortal || isLenderPortal) {
      const navItems = [
        myClosings,
        ...(showIdentityCRM ? [identityCRM] : []),
        tools,
        organizationSettings,
        teamMembers,
      ];
      if (isIHNOrganization || user.verifyAgent) {
        navItems.splice(1, 0, meetings);
      }

      if (isTitlePortal) {
        navItems.push(getStarted);
      }
      return navItems;
    }

    if (isCommandCenter) {
      const navItems = [companyDirectory, userManagement];
      if (canUseAccessControls) {
        navItems.push(access);
      }
      if (settingProfilesFlag && canUseSettingProfiles) {
        navItems.push(brands);
      }
      return navItems;
    }

    return [];
  }

  // If updating this for business users, please also update the same function in ../mobile_nav
  function filterByPermissionsAndFeatures(items: NavItemProps[]) {
    return items.filter((item) => {
      switch (item) {
        case getStarted:
          return (
            !hideGetStarted &&
            (canViewTeamDetails || canViewOrganizationDetails || canViewOrganizationTransactions)
          );
        case meetings:
          return canViewMeetings;
        case sendAndManage:
          return canViewOrganizationTransactions;
        case tools:
          return moveTools && canViewTools;
        case organizationSettings:
          return canViewOrganizationDetails;
        case teamMembers:
          return canViewTeamDetails;
        case myClosings:
          return canViewOrganizationTransactions;
        default:
          return true;
      }
    });
  }

  return (
    <ul>
      {filterByPermissionsAndFeatures(getNavItems()).map((navItemProps) => (
        <NavItem
          key={navItemProps.handleUrl}
          isCollapsed={sideNavCollapsed}
          {...navItemProps}
          featureAnnouncement={
            navItemProps.iconName === "tools" &&
            referringOrgName && (
              <FeatureAnnouncement
                heading={intl.formatMessage(MESSAGES.referringOrgHeading)}
                description={intl.formatMessage(MESSAGES.referringOrg, { referringOrgName })}
                arrowPlacement="left"
                onDismiss={trackAndRemoveActivationCookie}
              />
            )
          }
        />
      ))}
    </ul>
  );
}
